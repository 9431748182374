import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";

import {
  faChevronDown,
  faChevronRight,
  faEllipsisV,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import {
  Badge,
  Button,
  Col,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";

import InformationModal from "../../../InformationModal";

import {
  useWorkOrderDetails,
  ACTIONS,
  STATUSES,
} from "../../../../providers/workOrderDetailsProvider";
import Technicians from "./Technicians";
import CranesServiced from "./CranesServiced";
import MaterialsOrdered from "./MaterialOrdered";
import WorkOrderCloseOut from "./WorkOrderCloseOut";
import { workOrdersApi } from "../../../../services/workOrdersServices";
import Loader from "../../../Loader";
import CustomerApproval from "../CustomerApproval";
import CranesInspected from "./CranesInspected";
import ConfirmationModal from "../../../ConfirmationModal";
import GeneralService from "./GeneralService";
import JSAAnalysisSection from "./JSAAnalysisSection";
import { utils } from "../../../../utils/utils";
import { employeeWorkDaysApi } from "../../../../services/employeeWorkDaysServices";
import { useEffect } from "react";
import AddWorkTimeModal from "../../AddWorkTimeModal";
import { JSAItemAPI } from "../../../../services/JSAItemService";

const TYPE_INSPECTION = "Inspection";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const WorkOrderWorkPerformed = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [loading, setLoading] = useState();
  const [addTimeModal, setAddWorkTimeModal] = useState(false);

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [dropdownOpen, setDropdownOpen] = useState(null);

  const [materials, setMaterials] = useState([]);
  const [totalJSAItems, setTotalJSAItems] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const [materialsResponse, JSAItemsResponse] = await Promise.all([
          workOrdersApi.getMaterials({}),
          JSAItemAPI.getItems({}),
        ]);

        setMaterials(materialsResponse.data);
        setTotalJSAItems(JSAItemsResponse.count);
      } catch (error) {
        setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleDate = (date) => {
    if (workOrderDetails.activeDate !== date) {
      setWorkOrderDetails({
        action: ACTIONS.CHANGE_DATE,
        payload: {
          date,
        },
      });
    } else {
      setWorkOrderDetails({
        action: ACTIONS.CHANGE_DATE,
        payload: {
          date: null,
        },
      });
    }
  };

  const updateWOStatus = async (statusId) => {
    setLoading(true);
    try {
      await workOrdersApi.updateWorkOrder({
        ...workOrderDetails.workOrder,
        statusId,
      });
      setLoading(false);
      setWorkOrderDetails({
        action: ACTIONS.REFRESH,
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Work Order Details",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const changeWOStatusReadyToInvoice = async () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        try {
          await workOrdersApi.changeStatusReadyToInvoice({
            id: workOrderDetails.workOrder.id,
          });
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Work Order Details",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Approve work order for invoicing",
      body: `
        <p class="text-center">
          Do you confirm you want to approve this work order for invoicing?
        </p>
        <strong>${workOrderDetails.workOrder.jobNo}</strong>
      `,
      confirmColor: "warning",
    });
  };

  const onRemoveEmptyDays = (evt, employeeWorkDays) => {
    evt.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        try {
          for (const employeeWorkDay of employeeWorkDays) {
            await employeeWorkDaysApi.deleteEmployeeWorkDays({
              id: employeeWorkDay.id,
            });
          }
          setInformationModal({
            isOpen: true,
            title: "Remove Empty Day",
            body: "Empty Day Removed Successfully.",
            onClose: () => {
              setLoading(false);
              setWorkOrderDetails({
                action: ACTIONS.REFRESH,
              });
            },
          });
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Remove Empty Day",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Empty Day",
      body: `
        <span class="text-center">
          Do you confirm you want to remove this empty day?
        </span>
      `,
      confirmColor: "danger",
    });
  };

  const toggle = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const onRemoveWorkDayInformation = (date, employeeWorkDays) => {
    setConfirmationModal({
      isOpen: true,
      body: `
      <span class="text-center">
        Confirm you would like to delete this information:
        <br/>
        <br/>
        <strong>Work Day: ${moment(date).format("MM/DD/YYYY")}</strong>
        <br/>
        <strong>This action can not be undone.</strong>
      </span>
    `,
      title: `Are you sure you want to delete all the data for ${moment(
        date
      ).format("MM/DD/YYYY")}?`,
      confirmColor: "danger",
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        try {
          for (const employeeWorkDay of employeeWorkDays) {
            await employeeWorkDaysApi.deleteEmployeeWorkDays({
              id: employeeWorkDay.id,
            });
          }
          setInformationModal({
            isOpen: true,
            title: "Remove Work Day",
            body: `Work Day ${moment(date).format(
              "MM/DD/YYYY"
            )} Removed Successfully.`,
            onClose: () => {
              setLoading(false);
              setWorkOrderDetails({
                action: ACTIONS.REFRESH,
              });
            },
          });
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: `Delete Work Day ${moment(date).format("MM/DD/YYYY")}`,
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
    });
  };

  return (
    <Row className="mt-3">
      <Col sm="12">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center flex-grow-1 px-0">
            <h4 className="mb-0">Work Performed</h4>
            <div>
              <Button
                color="success"
                size="sm"
                className="ml-2 rounded"
                onClick={() => setAddWorkTimeModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                <span>Add Time</span>
              </Button>
              {workOrderDetails.workOrder.status.name ===
              STATUSES.STATUS_COMPLETED ? (
                <Button
                  size="sm"
                  color="secondary"
                  className="rounded ml-2"
                  onClick={changeWOStatusReadyToInvoice}
                >
                  <span className="font-500">Approve for Invoicing</span>
                </Button>
              ) : null}
            </div>
          </div>
          {!workOrderDetails.materialsOrdered.filter((mo) => !mo.received)
            .length &&
          workOrderDetails.workOrder.status.name ===
            STATUSES.STATUS_WAITING_FOR_PARTS ? (
            <Button
              className="rounded"
              size="sm"
              color="primary"
              onClick={() => updateWOStatus(STATUSES.STATUS_SCHEDULED)}
            >
              Mark as Scheduled
            </Button>
          ) : null}
        </div>
        {!workOrderDetails.employeeWorkDaysByDate ||
          !Object.keys(workOrderDetails.employeeWorkDaysByDate).length ? (
          <>
            <div className="text-center text-muted mt-4">
              No data has been recorded
            </div>
            <WorkOrderCloseOut />
            <CustomerApproval />
          </>
        ) : loading ? (
          <Loader size="sm" />
        ) : (
          <>
            <MaterialsOrdered />
            {Object.keys(workOrderDetails.employeeWorkDaysByDate)
              .sort(utils.sortDays)
              .map((date, index) => {
                const employeeWorkDays =
                  workOrderDetails.employeeWorkDaysByDate[date];

                const isEmpty = utils.isWorkDayEmpty(employeeWorkDays);

                const trips = workOrderDetails.workOrder.workOrderTrips
                  .filter((trip) =>
                    employeeWorkDays.find(
                      (ewd) => ewd.workOrderTripId === trip.id
                    )
                  )
                  .sort((x, y) =>
                    moment(x.startDate).isBefore(moment(y.startDate), "date")
                      ? 1
                      : -1
                  );

                return isEmpty ? (
                  <div
                    className="mt-3 d-flex justify-content-between align-items-center p-2 border rounded"
                    key={date}
                  >
                    <span className="text-muted">
                      {moment(date).format("MM/DD/YYYY")}
                    </span>
                    <Button
                      color="danger"
                      onClick={(evt) =>
                        onRemoveEmptyDays(evt, employeeWorkDays)
                      }
                      className="cursor-pointer mr-2 rounded"
                      size="sm"
                    >
                      Remove Empty Day
                    </Button>
                  </div>
                ) : (
                  <div className="mt-3" key={date}>
                    <div className="d-flex justify-content-between">
                      <Button
                        color="primary"
                        className="w-100"
                        key={date}
                        onClick={() => toggleDate(date)}
                        style={{ marginRight: 6 }}
                      >
                        <div className="d-flex flex-row justify-content-between m-1 align-middle">
                          <span>{moment(date).format("MM/DD/YYYY")}</span>
                          <div className="d-flex">
                            {workOrderDetails.activeDate === date ? (
                              <Badge
                                color="primary"
                                pill
                                className="border border-white mr-1 pt-1"
                              >
                                {utils.getLastEdited(employeeWorkDays)}
                              </Badge>
                            ) : null}
                            <FontAwesomeIcon
                              icon={
                                workOrderDetails.activeDate === date
                                  ? faChevronDown
                                  : faChevronRight
                              }
                              fixedWidth
                              className="my-1"
                            />
                          </div>
                        </div>
                      </Button>
                      <Dropdown
                        isOpen={dropdownOpen === index}
                        toggle={() => toggle(index)}
                      >
                        <DropdownToggle
                          color="primary"
                          size="sm"
                          style={{ height: "100%" }}
                          className="rounded"
                        >
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </DropdownToggle>
                        <DropdownMenu right className="mt-5 text-center">
                          <DropdownItem className="bg-transparent cursor-default">
                            <div
                              onClick={() =>
                                onRemoveWorkDayInformation(
                                  date,
                                  employeeWorkDays
                                )
                              }
                            >
                              <div className="btn btn-danger btn-sm rounded font-weight-bold">
                                Delete Work Day
                              </div>
                            </div>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <Collapse
                      isOpen={workOrderDetails.activeDate === date}
                      className="px-3"
                    >
                      <h5 className="my-3 pl-1 pb-2 border-bottom">
                        Service Requested
                      </h5>
                      <ListGroup className="my-2 col-12 px-0">
                        <ListGroupItem className="d-flex justify-content-between align-items-center bg-lighter">
                          <span>
                            {utils.getWoServiceRequested(
                              workOrderDetails.workOrder
                            )}
                          </span>
                        </ListGroupItem>
                      </ListGroup>
                      <ListGroup className="my-2 col-12 px-0">
                        {trips.length ? (
                          trips
                            .sort((x, y) => x.tripNumber - y.tripNumber)
                            .map((trip) => (
                              <div key={trip.id} className="mb-3">
                                <h5 className="my-3 pl-1 pb-2 border-bottom">
                                  Trip {trip.tripNumber}
                                </h5>
                                <ListGroupItem className="d-flex flex-column border-0 p-0 mt-n2">
                                  <JSAAnalysisSection
                                    date={date}
                                    trip={trip}
                                    totalJSAItems={totalJSAItems}
                                  />
                                  <Technicians date={date} trip={trip} />
                                  {workOrderDetails.workOrder.jobType ===
                                  TYPE_INSPECTION ? (
                                    <>
                                      <GeneralService
                                        trip={trip}
                                        date={date}
                                        isInspection={true}
                                      />
                                      <CranesInspected
                                        date={date}
                                        trip={trip}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <GeneralService
                                        trip={trip}
                                        date={date}
                                        materials={materials}
                                      />
                                      <CranesServiced
                                        trip={trip}
                                        date={date}
                                        materials={materials}
                                      />
                                    </>
                                  )}
                                </ListGroupItem>
                              </div>
                            ))
                        ) : (
                          <ListGroupItem className="d-flex justify-content-between align-items-center bg-lighter">
                            There are no trips for the date
                          </ListGroupItem>
                        )}
                      </ListGroup>
                    </Collapse>
                  </div>
                );
              })}
            <WorkOrderCloseOut />
            <CustomerApproval />
          </>
        )}
        {informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            rawBody={informationModal.rawBody}
            onClose={() =>
              informationModal.onClose
                ? informationModal.onClose()
                : setInformationModal({
                    rawBody: false,
                    isOpen: false,
                    title: "",
                    body: "",
                  })
            }
          />
        ) : confirmationModal.isOpen ? (
          <ConfirmationModal {...confirmationModal} />
        ) : addTimeModal ? (
          <AddWorkTimeModal
            onSubmit={() => {
              setAddWorkTimeModal(false);
              setInformationModal({
                isOpen: true,
                title: `Add Time`,
                body: "Time added successfully.",
                onClose: () => {
                  setInformationModal({
                    isOpen: false,
                    title: "",
                    body: "",
                    onClose: null,
                  });
                  setWorkOrderDetails({
                    action: ACTIONS.REFRESH,
                  });
                },
              });
            }}
            onClose={() => setAddWorkTimeModal(false)}
          />
        ) : null}
      </Col>
    </Row>
  );
};
export default WorkOrderWorkPerformed;
